.delete-account-presentational {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;

    overflow: hidden;
    color: #AFC3BC;
    font-weight: 400;
    font-family: groteskText;

    .delete-account-content {
        z-index: 1;
        background: #1E2A2C;
        border-radius: 43px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px;
        width: 400px;
        text-align: center;
        .logo {
            width: 320px;
            height: 38px;
        }
        > .title {
            font-size: 24px;
            line-height: 29px;
            margin: 40px 0;
            font-family: pitchsan, sans-serif;
            text-transform: uppercase;
            font-weight: 700;
        }
        > .initiate-form {
            margin-top: 20px;
            .initiate-input {
                width: 320px;
                background: #FAF9F6;
                border-radius: 272px;
                padding: 15px;
                text-align: center;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.155em;
                font-family: pitchsan, sans-serif;
                margin-bottom: 16px;
                &::placeholder {
                    text-transform: uppercase;
                }
            }
            .initiate-btn {
                padding: 15px 25px;
                margin-top: 20px;
                background: #AFC3BC;
                color: #1e555c;
                border-radius: 272px;
                width: 162px;

                font-weight: bold;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 0.155em;
                text-transform: uppercase;

                border: unset !important;

                font-family: pitchsan, sans-serif;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .delete-account-presentational {
        .delete-account-content {
            padding: 40px 20px;
            border-radius: 20px;
            margin: 0 20px;
            .logo {
                width: 100%;
                max-width: 420px;
                height: unset;
            }
            .initiate-form {
                width: 100%;
            }
        }
    }
}
